import React, { useEffect, useMemo, useRef, useState, useCallback } from "react";
import "./CreateAlert.scss";
import * as PR from "../../prime-react/index";
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { getLocations, getSports, getTournament, getTournamentLevels, geteSportsTitles} from '../../services/api'
import { useDispatch, useSelector } from "react-redux";
import { aletFormForValidation, debounce, descriptionCount, displayStatesForSelectedCountries, fileTypesConfig, fileUploadLimit, getTimeZoneOptions, maxNumberOfFiles, textSanitize, handleNumericInput, getPlainText, validationMessages} from "../../utils/reuse";
import { RAW_FILTER_DATA } from '../../shared/common';
import moment from 'moment';
import { ErrorMessage, useToast } from "../../context/ToastContext";

const AlertForm = ({initialData, handleBack, handleNext, editForm, backLabel, nextLabel, showError, setshowError, responseID, alertLoading, errorId}) => {
    const { showToast } = useToast();
    const dispatch = useDispatch();
    const sessionData = useSelector(state => state.auth.sessionData);
    const headers = useMemo(() => {
        return { 
            sessionid: sessionData.sessionId,
            'Content-Type': 'multipart/form-data'
        };
    }, [sessionData.sessionId]);

    const isMember = useSelector((state) => state.auth.role) === "member";
    const alerts = RAW_FILTER_DATA.alertTypeFilter;
    const channels = RAW_FILTER_DATA.channels;
    const betTimeOptions = RAW_FILTER_DATA.timeOfBet;
    const betsStateOptions = RAW_FILTER_DATA.stateOfBetsFilter;
    const confirmOptions = RAW_FILTER_DATA.confirmOptions;
    const alertsRaised = RAW_FILTER_DATA.alertsRaised;
    const customerTypeOptions = RAW_FILTER_DATA.customerTypes;
    const gender = RAW_FILTER_DATA.gender;

    let fileUploadRef = useRef(null);  //file ref

    const [events, setEvents] = useState([]);
    const [sports, setSports] = useState([]);
    const [eSportTitles, seteSportTitles] = useState([]);
    const [location, setLocation] = useState([]);
    const [tournamentLevel, setTournamentLevel] = useState([]);
    const [tournamentList, setTournamentList] = useState([]);
    const [filteredTournaments, setFilteredTournaments] = useState([]);

    const [maxBets, setMaxBets] = useState('');
    const [alertTime, setAlertTime] = useState('');
    const [initialValues, setInitialValues] = useState({
        numOfEvents: '',
        events: [],
        channel: [],
        betsState: [],
        betTime: [],
        maxBets: '',
        alertTime: '',
        customerType: [],
        customers: [],
        newCustomer: { count: '', country: '' },
        description: '',
        attachments: null,
      })
      const [timezones, setTimezones] = useState([]);
      const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

      const validationSchema = () => {
        let schema = Yup.object().shape({
            numOfEvents:  Yup.number().required('Number of events required')
                        .min(1, 'Number of events must be greater than or equal to 1')
                        .max(10, 'Number of events must be less than or equal to 10'), 
            channel: Yup.array().min(1, 'At least one channel must be selected'),
            betsState: Yup.array().min(1, 'At least one state of bets must be selected'),
            betTime: Yup.array().min(1, 'At least one timing of bet must be selected'),
            customerType:  Yup.array().min(1, 'At least one customer type must be selected'),
            maxBets: Yup.string().required('Max Bets is required'),
            customers: Yup.array().of(
                Yup.object().shape({
                    count:Yup.number().typeError('Number of customers must be a number').min(1, 'Number of customers must be at least 1').required('Number of customers is required'),
                    country: Yup.object().nullable().required('Country required'),
                    state: Yup.string().when('country', {
                        is: (country) => {
                            return displayStatesForSelectedCountries.includes(country?.name);
                        },
                        then: () => Yup.string().required('State required'),
                        otherwise: () => Yup.string().nullable(),
                    }),
                })
            ),
            newCustomer: Yup.object().shape({
                count: Yup.number()
                    .typeError('Number of customers must be a number')
                    .required('Number of customers is required')
                    .min(0, 'Number of customers must be greater than or equal to 0'),
                country: Yup.object().nullable().required('Country required'),
                state: Yup.string().when('country', {
                    is: (country) => {
                        return displayStatesForSelectedCountries.includes(country?.name);
                    },
                    then: () => Yup.string().required('State required'),
                    otherwise: () => Yup.string().nullable(),
                }),
            }),
            description: Yup.string().test("valid-text", "Enter valid text", (value) => !(getPlainText(value) === 0 && value)).required('Description required').max(descriptionCount, `Description should be ${descriptionCount} characters or less.`),
            attachments: Yup.array()
                .max(maxNumberOfFiles, validationMessages.maxNumberOfFiles)
                .nullable()
                .test('fileType', validationMessages.allFileTypesWithoutImage, (files) => {
                    if (!files) return true;
                    return files?.every((file) => fileTypesConfig.includes(file.type))
                })
                .test('fileSize', validationMessages.sizeExceed, (files) => {
                    if (!files) return true;
                    return files?.every((file) => {
                        return file.size <= fileUploadLimit.value
                    })
                })
                .test('uniqueFileNames', validationMessages.uniqueFileNames, (value) => {
                    if (!value || value.length <= 1) return true;
                    const fileNames = value.map((file) => file.name);
                    const uniqueFileNames = new Set(fileNames);
                    return fileNames.length === uniqueFileNames.size;
                })
        });

        // Dynamically add validation rules for event fields
        for (let i = 0; i < events.length; i++) {
            schema = schema.shape({
                [`eventName-${i}`]: Yup.string().nullable(),
                [`sport-${i}`]: Yup.object().nullable(),
                [`eSportTitle-${i}`]: Yup.object().nullable(),
                [`league-${i}`]: Yup.mixed().nullable(),
                [`tournament-${i}`]: Yup.object().nullable(),
                [`location-${i}`]: Yup.object().nullable(),
                [`gender-${i}`]: Yup.string().nullable(),
                [`dateTime-${i}`]: Yup.date().nullable(),
                [`turnover-${i}`]: Yup.number().when(`isMember`, {
                    is: () =>  isMember === true ,
                    then: () => Yup.number().required('Turnover is required').min(1, 'Turnover must be greater than or equal to 1'),
                    otherwise: () =>  Yup.number().nullable(),
                }),
            });
        }
        return schema;
    };
    // Fetch timezones with type
    useEffect(() => {
        const fetchTimeZones = async () => {
            try {
                const tzOptions = getTimeZoneOptions();
                setTimezones(tzOptions);
            } catch (error) {
                console.error("Error fetching time zones:", error);
            }
        };

        fetchTimeZones();
    }, []);

    // Get list of all the eSports Titles
    const getEsportsTitlesHandler = useCallback(() => {
        geteSportsTitles(headers, dispatch, response => {
            if (response.result === 'SUCCESS') {
                const responseData = response.data;
                const eSportTitles = responseData;
                const result = (eSportTitles?.length > 0) ? eSportTitles?.sort((a, b) => a.name.localeCompare(b.name)) : [];
                seteSportTitles(result);
            } else {
                seteSportTitles([]);
                const error = response.error;
                showToast(error.severity, error?.errorMsg || error?.summary);
            }
        })
    }, [dispatch, headers, showToast]);

    // Get list of all the tournament levels
    const getTournmamentLevelsHandler = useCallback(() => {
        getTournamentLevels(null, headers, dispatch, response => {
            if (response.result === 'SUCCESS') {
                const responseData = response.data;
                const tournamentLevel = responseData;
                const result = (tournamentLevel?.length > 0) ? tournamentLevel : [];
                setTournamentLevel(result);
            } else {
                setTournamentLevel([]);
                const error = response.error;
                showToast(error.severity, error?.errorMsg || error?.summary);
            }
        })
    }, [dispatch, headers, showToast]);


    // Get all the tournaments based on SportId
    const getTournamenthandler = useCallback((sportId) => {
        const obj = {
            sportid: sportId,
        };
        getTournament(obj, headers, dispatch, response => {
            if (response.result === 'SUCCESS') {
                const responseData = response.data;
                const tournaments = responseData;
                setTournamentList(tournaments);
                setFilteredTournaments(tournaments);
            } else {
                setTournamentList([]);
                setFilteredTournaments([]);
                const error = response.error;
                showToast(error.severity, error?.errorMsg || error?.summary);
            }
        })
    }, [dispatch, headers, showToast]);
    // Get list of all the sports
    const getSportsHandler = useCallback(() => {
        getSports(null, headers, dispatch, response => {
            if (response.result === 'SUCCESS') {
                const responseData = response.data;
                const sports = responseData;
                const result = (sports?.length > 0) ? sports?.sort((a, b) => a.name.localeCompare(b.name)) : [];
                setSports(result);
                if (result.length === 0) {
                    showToast("warn", "No records found");
                }
            } else {
                setSports([]);
                const error = response.error;
                showToast(error.severity, error?.errorMsg || error?.summary);
            }
        })
    }, [dispatch, headers, showToast]);

    // Get list of all the locations
    const getLocationHandler = useCallback(() => {
        getLocations(headers, dispatch, response => {
            if (response.result === 'SUCCESS') {
                const responseData = response.data;
                const locations = responseData;
                const result = (locations?.length > 0) ? locations?.sort((a, b) => a.name.localeCompare(b.name)) : [];
                setLocation(result);
                if (result.length === 0) {
                    showToast("warn", "No records found");
                }
            } else {
                setLocation([]);
                const error = response.error;
                showToast(error.severity, error?.errorMsg || error?.summary);
            }
        })
    }, [dispatch, headers, showToast]);


    useEffect(() => {
        getSportsHandler();
        getLocationHandler();
        getTournmamentLevelsHandler();
        getEsportsTitlesHandler();

    }, [getSportsHandler, getLocationHandler, getTournmamentLevelsHandler, getEsportsTitlesHandler]);

    useEffect(() => {
        let updatedInitialValues = {};

        //alertType
        let alertTypeName = alerts.find(item => item.id === initialData?.alertType)?.name;
        updatedInitialValues['alertType'] = { name: alertTypeName, id: initialData?.alertType }

        //channel
        updatedInitialValues['channel'] = [];

        //timeOfBet
        updatedInitialValues['betTime'] = [];

        //betsState
        updatedInitialValues['betsState'] = [];

        //CustomerType
        updatedInitialValues['customerType'] = [];

        updatedInitialValues['customers'] = [];

        let eventsData = initialData?.events;
        let updatedEvents = [];
        updatedEvents = eventsData?.map((event, index) => {

            const formikEventData = {
                [`eventName-${index}`]: event['eventName'],
                [`sport-${index}`]: { name: event['sportName'], id: event['sportID'] },
                [`eSportTitle-${index}`]: { name: event['esportsTitleName'], id: event['esportsTitleID'] },
                [`league-${index}`]: { name: event['tournamentName'], id: event['tournamentID'] },
                [`tournament-${index}`]: { name: event['tournamentLevelName'], id: event['tournamentLevelID'] },

                [`eventID-${index}`]: event['eventID'],
                [`dateTime-${index}`]: moment.utc(event.dateTime).local().toDate(),
                [`location-${index}`]: { name: event['locationName'], id: event['locationID'] },
                [`gender-${index}`]: event['gender'],
            };

            if (event['sportName']?.toLowerCase() === 'tennis') {
                formikEventData[`tournamentLevelOptions-${index}`] = tournamentLevel
            } else {
                formikEventData[`tournamentLevelOptions-${index}`] = []
            }

            if(event['sportName']?.toLowerCase() === 'esports'){
                formikEventData[`eSportTitlesOptions-${index}`] = eSportTitles
            }else{
                formikEventData[`eSportTitlesOptions-${index}`] = []
            }

            const updatedEvent = {
                eventName: event['eventName'],
                sport: { name: event['sportName'], id: event['sportID'] },
                eSportTitle: { name: event['esportsTitleName'], id: event['esportsTitleID'] },
                league: { name: event['tournamentName'], id: event['tournamentID'] },
                tournament: { name: event['tournamentLevelName'], id: event['tournamentLevelID'] },

                eventID: event['eventID'],
                dateTime: moment.utc(event.dateTime).local().toDate(),
                timezone: timezone,
                location: { name: event['locationName'], id: event['locationID'] },
                gender: event['gender'],
            };

            updatedInitialValues = { ...updatedInitialValues, ...formikEventData };

            return updatedEvent;
        });

        //num of Events
        updatedInitialValues['numOfEvents'] = updatedEvents.length;
        updatedInitialValues['attachments'] = [];
        setEvents(updatedEvents);
        setInitialValues(updatedInitialValues);
    }, [location, initialData, alerts, timezone, tournamentLevel, eSportTitles]);


    const handleSubmit = (formData) => {
        let data = {};
        data.channel = formData.channel;
        data.timeOfBet = formData.betTime;
        data.stateOfBet = formData.betsState;
        data.maxBets = formData.maxBets;
        data.timeOfAlert = initialData?.timeOfAlert;    //will remove this later

        let customers = formData.customers;
        customers?.push(formData.newCustomer);
        let customerDetails = formatCustomerInfo(customers);

        data.customer = { 
            customerType: formData.customerType,
            customerDetails: customerDetails
        }

        data.description = textSanitize(formData.description);
        data.events = formatEventData(events);
        if (!editForm) {
            data.responseType = "offered and suspicious";
        }
        if(responseID){
            data.responseID = responseID;
        }

        const formResponse = new FormData();

        formResponse.append('data', JSON.stringify(data)); 
        if (formData.attachments && formData.attachments.length > 0) {
            formData.attachments.forEach((file, index) => {
                formResponse.append(`attachments`, file);
            });
        }
        handleNext(formResponse);
    };
    const formik = useFormik({
        initialValues,
        validationSchema: validationSchema,
        enableReinitialize: true,
        onSubmit: handleSubmit
    });
    
    const handleAdd = () => {
        const newCustomers = [...formik.values.customers, { count: '', country: '' }];
        formik.setFieldValue('customers', newCustomers);
    };
    const handleDelete = (index) => {
        const updatedCustomers = [...formik.values.customers];
        updatedCustomers.splice(index, 1);
        formik.setFieldValue('customers', updatedCustomers);
    };
    const formatEventData = (events) => {
        const updatedEvents = events.map(event => {
            let data = {}
            data.eventName = event.eventName;
            data.sportName = event.sport.name;
            if(data.sportName.toLowerCase() === 'esports' && event.eSportTitle.id){
                data.esportsTitleID = event.eSportTitle.id;
                data.esportsTitleName = event.eSportTitle.name;
            }
            data.sportID = event.sport.id;
            data.tournamentLevelID = event.tournament.id; 
            data.tournamentLevelName = event.tournament.name; 
            data.tournamentName = event.league.name;
            data.tournamentID = event.league.id;
            data.locationID = event.location.id;
            data.locationName = event.location.name;
            data.gender = event.gender;
            data.turnover = parseInt(event.turnover);
            const parsedDate = moment(event.dateTime).format('YYYY-MM-DDTHH:mm:ss');
            const dateInUTC = moment.tz(parsedDate, event.timezone).utc().format();
            data.dateTime = dateInUTC;
            return data;
        })
        return updatedEvents;
    }
    const formatCustomerInfo = (customers) => {
        const updatedCustomers = customers.map(customer => {
            let data = {}
            data.count = customer.count;
            data.country = customer.country.name;
            if(customer?.state){
                data.state = customer.state
            }
            return data;
        })
        return updatedCustomers;
    }


    const handleEventDetailChange = (index, value, property) => {
        const newEvents = [...events];
        newEvents[index][property] = value;
        setEvents(newEvents);
        formik.setFieldValue(`${property}-${index}`, value);
        if (property === 'sport') {
            newEvents[index].tournament = {};   // Reset tournament field
            newEvents[index].league = {}; // Reset tournament level field
            newEvents[index].eSportTitle = {};    
            formik.setFieldValue(`eSportTitle-${index}`, null);
            formik.setFieldValue(`tournament-${index}`, null);
            formik.setFieldValue(`league-${index}`, null);
            formik.setFieldValue(`eSportTitlesOptions-${index}`, [])
            formik.setFieldValue(`tournamentLevelOptions-${index}`, [])
            setEvents(newEvents);

            getTournamenthandler(value.id);
            if(value?.name.toLowerCase() === 'tennis'){
                formik.setFieldValue(`tournamentLevelOptions-${index}`, tournamentLevel)
            }

            if (value?.name?.toLowerCase() === 'esports') {
                formik.setFieldValue(`eSportTitlesOptions-${index}`, eSportTitles)
            }
         }   
         if(property === 'league') {
            if(value?.name.trim() === '') {
                formik.setFieldValue(`league-${index}`, null);
            } 
         } 
    }


    const handleMultiSelect = (e, fieldName) => {
        const { value } = e.target;
        const set = new Set(formik.values[fieldName]);
        if (set.has(value)) {
            set.delete(value);
        } else {
            set.add(value);
        }
        formik.setFieldValue(fieldName, Array.from(set));
    }

    const handleMaxBets = (value) => {
        setMaxBets(value);
        formik.setFieldValue('maxBets', value)
    }

    const handleAlertTime = (value) => {
        setAlertTime(value);
        formik.setFieldValue('alertTime', value)
    }

    const handleNumOfEventsChange = (e) => {
        const numOfEvents = parseInt(e.target.value);
        if (!isNaN(numOfEvents)) {
            const existingEventsCount = events.length;
            if (numOfEvents > existingEventsCount) {
                // Add new events
                const newEvents = [...events];
                for (let i = existingEventsCount; i < numOfEvents; i++) {
                    newEvents.push({ eventName: '', sport: null });
                }
                setEvents(newEvents);
            } else if (numOfEvents < existingEventsCount) {
                // Remove excess events
                const newEvents = events.slice(0, numOfEvents);
                setEvents(newEvents);
            }
            formik.setFieldValue('numOfEvents', numOfEvents);
        }
        formik.handleChange(e);
    };

    //this is for debouncing effect AutoComplete Onchange 
       const handleEventDetailChangeDebounce = debounce((index, value, property) => {
        handleEventDetailChange(index, value, property);
    }, 300);

  


    //  function to filter searched results of autocomplete 
    const search = (event) => {
        const query = event.query?.toLowerCase();
        const filtered = tournamentList
            .filter(tournament => tournament.name?.toLowerCase()?.includes(query));
        setFilteredTournaments(filtered);
    }
    
   

    const handleDeleteFile = (index) => {
        const updatedAttachments = [...formik.values.attachments];
        updatedAttachments.splice(index, 1);
        formik.setFieldValue('attachments', updatedAttachments);
    };

    const handleFileSelect = (event) => {
        if (fileUploadRef.current) {
            fileUploadRef.current.clear();
        }
        const currentAttachments = formik.values.attachments || [];
        const newAttachment = event.files[0];

        const duplicateFiles = currentAttachments.some(existingFile => existingFile.name === newAttachment.name)

        if (duplicateFiles) {
            showToast("warn", validationMessages.uniqueFileNames);
            fileUploadRef.current.clear();
        } else if (currentAttachments?.length + 1 > maxNumberOfFiles) {
            showToast("warn", validationMessages.maxNumberOfFiles);
            fileUploadRef.current.clear();
        } else {
            const updatedAttachments = [...currentAttachments, newAttachment];
            formik.setFieldValue("attachments", updatedAttachments);
        }
    }

    return (
        <>
            {/* Create alert form section starts here */}
            <div className="form-section">
                <form autoComplete="off" onSubmit={formik.handleSubmit}>
                    <div className="form-body">
                        <div className="grid align-items-center">
                            <div className="col-12 mb-2">
                                <div className="flex flex-column gap-2">
                                    <label htmlFor="alertType">Type of alert<span className="important">*</span></label>
                                    <PR.Dropdown
                                        inputId="alertType"
                                        value={formik.values.alertType}
                                        onChange={(e) => {
                                            formik.setFieldValue('alertType', e.value);
                                        }}
                                        onBlur={formik.handleBlur}
                                        name="alertType"
                                        options={alerts}
                                        optionLabel="name"
                                        placeholder="Select type of alert"
                                        className="w-full alert-drop-down"
                                        disabled={true}
                                    />
                                    {formik.errors.alertType && formik.touched.alertType && (
                                        <div className="error">{formik.errors.alertType}</div>
                                    )}
                                </div>
                            </div>
                            <div className="col-12">
                                <div className="flex flex-column form-input gap-2">
                                    <label htmlFor="numOfEvents">Number of events<span className="important">*</span></label>
                                    <PR.InputText
                                        id="numOfEvents"
                                        type="number"
                                        placeholder="Number of Events"
                                        onChange={handleNumOfEventsChange}
                                        onBlur={formik.handleBlur}
                                        value={formik.values.numOfEvents}
                                        aria-describedby="events-help"
                                        min={0}
                                        max={10}
                                        disabled={true}
                                    />
                                    {formik.errors.numOfEvents && formik.touched.numOfEvents && (
                                        <div className="error">{formik.errors.numOfEvents}</div>
                                    )}
                                </div>
                                {events.length !== 0 && <div className="events-section">
                                    {/* Event */}
                                    {events.map((event, index) => (
                                        <div className="grid align-items-baseline" key={index}>
                                            <div className="custom-col">
                                                <span className="number">{index + 1}</span>
                                            </div>
                                            <div className="col">
                                                <div className="flex flex-column form-input gap-2 mb-4">
                                                    <label htmlFor={`eventName-${index}`}>Event name<span className="important">*</span></label>
                                                    <PR.InputText
                                                        type="text"
                                                        aria-describedby="eventname"
                                                        id={`eventName-${index}`}
                                                        name={`eventName-${index}`}
                                                        onBlur={formik.handleBlur}
                                                        value={event.eventName}
                                                        onChange={(e) => handleEventDetailChange(index, e.target.value, 'eventName')}
                                                        disabled={true}
                                                    />
                                                    {(formik.touched[`eventName-${index}`] || formik.submitCount > 0) && formik.errors[`eventName-${index}`] && (
                                                        <div className="error">{formik.errors[`eventName-${index}`]}</div>
                                                    )}
                                                </div>
                                                <div className="flex flex-column gap-2 mb-4">
                                                    <label htmlFor={`sport-${index}`}>Select sport<span className="important">*</span></label>
                                                    <PR.Dropdown
                                                        value={event.sport}
                                                        name={`sport-${index}`}
                                                        inputId={`sport-${index}`}
                                                        onChange={(e) => handleEventDetailChange(index, e.value, 'sport')}
                                                        options={sports} optionLabel="name"
                                                        placeholder="Select sport"
                                                        onBlur={formik.handleBlur}
                                                        className="w-full alert-drop-down"
                                                        disabled={true}
                                                        filter
                                                    />
                                                    {(formik.touched[`sport-${index}`] || formik.submitCount > 0) && formik.errors[`sport-${index}`] && (
                                                        <div className="error">{formik.errors[`sport-${index}`]}</div>
                                                    )}
                                                </div>
                                                {event?.sport?.name?.toLowerCase() === 'esports' &&
                                                    <div className="flex flex-column gap-2 mb-4">
                                                        <label htmlFor={`eSportTitle-${index}`}>Esports Title<span className="important">*</span></label>
                                                        <PR.Dropdown
                                                            value={event.eSportTitle}
                                                            inputId={`eSportTitle-${index}`}
                                                            name={`eSportTitle-${index}`}
                                                            onChange={(e) => handleEventDetailChange(index, e.value, 'eSportTitle')}
                                                            options={formik.values[`eSportTitlesOptions-${index}`]}
                                                            optionLabel="name"
                                                            filter
                                                            onBlur={formik.handleBlur}
                                                            placeholder="Select Esports Title"
                                                            className="w-full alert-drop-down"
                                                            disabled={true}
                                                        />
                                                        {(formik.touched[`eSportTitle-${index}`] || formik.submitCount > 0) && formik.errors[`eSportTitle-${index}`] && (
                                                            <div className="error">{formik.errors[`eSportTitle-${index}`]}</div>
                                                        )}
                                                    </div>
                                                }
                                                <div className="grid mb-4">
                                                <div className="col-6">
                                                        <div className="flex flex-column form-input gap-2">
                                                            <label htmlFor={`league-${index}`}>League / Tournament<span className="important">*</span></label>
                                                               <PR.AutoComplete 
                                                                inputId={`league-${index}`}
                                                                name={`league-${index}`}
                                                                onBlur={formik.handleBlur}
                                                                dropdownAutoFocus={true}
                                                                value={event.league ? event.league?.name : ''}
                                                                onChange={(e) => {
                                                                    if (typeof e.value === 'string') {
                                                                        handleEventDetailChangeDebounce(index, { name: e.value }, 'league'); // value is a string
                                                                    } else if (e.value) {
                                                                        handleEventDetailChangeDebounce(index, e.value, 'league'); //value is an object
                                                                    }
                                                                }}
                                                                aria-describedby="league"
                                                                suggestions={filteredTournaments} 
                                                                completeMethod={search} 
                                                                itemTemplate={(tournament) => tournament.name}
                                                                className="form-autocomplete"
                                                                // disabled={!event.sport}
                                                                disabled={true}
                                                                            />
                                                            {(formik.touched[`league-${index}`] || formik.submitCount > 0) && formik.errors[`league-${index}`] && (
                                                                <div className="error">{formik.errors[`league-${index}`]}</div>
                                                            )}
                                                        </div>
                                                    </div>
                                                    <div className="col-6">
                                                        <div className="flex flex-column gap-2">
                                                        <label htmlFor={`tournament-${index}`}>Tournament level {(event.sport?.name.toLowerCase() === 'tennis') && <span className="important">*</span>}</label>
                                                        <PR.Dropdown
                                                                value={event.tournament}
                                                                inputId={`tournament-${index}`}
                                                                name={`tournament-${index}`}
                                                                onChange={(e) => handleEventDetailChange(index, e.value, 'tournament')}
                                                                options={formik.values[`tournamentLevelOptions-${index}`]}
                                                                optionLabel="name"
                                                                onBlur={formik.handleBlur}
                                                                placeholder="Select tournament level"
                                                                className="w-full alert-drop-down"
                                                                // disabled={formik.values[`tournamentLevelOptions-${index}`]?.length <= 0}
                                                                disabled={true}
                                                            />
                                                            {(formik.touched[`tournament-${index}`] || formik.submitCount > 0) && formik.errors[`tournament-${index}`] && (
                                                                <div className="error">{formik.errors[`tournament-${index}`]}</div>
                                                            )}
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="grid mb-4">
                                                    <div className="col-6">
                                                        <div className="flex flex-column gap-2">
                                                            <label htmlFor={`dateTime-${index}`}>Date / Time of event <span style={{ color: '#5b5858' }}> (Format: dd/mm/yyyy hh:mm)</span><span className="important">*</span></label>
                                                            <PR.Calendar
                                                                inputId={`dateTime-${index}`}
                                                                name={`dateTime-${index}`}
                                                                placeholder="Select date"
                                                                value={event.dateTime}
                                                                onFocus={() => formik.setFieldTouched(`dateTime-${index}`)}
                                                                onChange={(e) => handleEventDetailChange(index, e.value, 'dateTime')}
                                                                showTime hourFormat="24"
                                                                showIcon iconPos="right"
                                                                className="date-calendar"
                                                                disabled={true}
                                                                dateFormat="dd/mm/yy"
                                                                onKeyDown={(e) => {
                                                                    if (e.key === 'Enter') {
                                                                        e.preventDefault(); // Prevent form submission
                                                                    }
                                                                }}
                                                            />
                                                            {(formik.touched[`dateTime-${index}`] || formik.submitCount > 0) && formik.errors[`dateTime-${index}`] && (
                                                                <div className="error">{formik.errors[`dateTime-${index}`]}</div>
                                                            )}
                                                        </div>
                                                        <div className="flex flex-column gap-2 mt-2">
                                                            <PR.Dropdown
                                                                value={event.timezone}
                                                                name={`timezone-${index}`}
                                                                onChange={(e) => handleEventDetailChange(index, e.value, 'timezone')}
                                                                options={timezones}
                                                                onBlur={formik.handleBlur}
                                                                filter
                                                                placeholder="Select timezone"
                                                                className="w-full alert-drop-down"
                                                                resetFilterOnHide
                                                                disabled={true}
                                                            />
                                                            {(formik.touched[`timezone-${index}`] || formik.submitCount > 0) && formik.errors[`timezone-${index}`] && (
                                                                <div className="error-message">{formik.errors[`timezone-${index}`]}</div>
                                                            )}
                                                        </div>
                                                    </div>
                                                    <div className="col-6">
                                                        <div className="flex flex-column gap-2">
                                                            <label htmlFor={`location-${index}`}>Location of event<span className="important">*</span></label>
                                                            <PR.Dropdown
                                                                value={event.location}
                                                                inputId={`location-${index}`}
                                                                name={`location-${index}`}
                                                                onChange={(e) => handleEventDetailChange(index, e.value, 'location')}
                                                                options={location} optionLabel="name"
                                                                onBlur={formik.handleBlur}
                                                                placeholder="Select event location"
                                                                className="w-full alert-drop-down"
                                                                disabled={true}
                                                                filter
                                                            />
                                                            {(formik.touched[`location-${index}`] || formik.submitCount > 0) && formik.errors[`location-${index}`] && (
                                                                <div className="error">{formik.errors[`location-${index}`]}</div>
                                                            )}
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="grid mb-4">
                                                    <div className="col-12">
                                                        <div className="flex flex-column gap-2">
                                                            <span className="label">Gender<span className="important">*</span></span>
                                                            <div className="card flex mt-1 gap-3">
                                                            {gender.map((option) => (    
                                                                <div className="flex flex-wrap gap-2" key={option.id}>
                                                                <div className="flex align-items-center">
                                                                    <PR.RadioButton
                                                                        inputId={`gender-${option.id}${index}`}
                                                                        name="gender"
                                                                        value={option.id}
                                                                        onChange={(e) => handleEventDetailChange(index, e.value, 'gender')}
                                                                        checked={event.gender === option.id}
                                                                        disabled={true}
                                                                    />
                                                                    <label htmlFor={`gender-${option.id}${index}`} className="ml-2">{option.name}</label>
                                                                    </div>
                                                                </div>
                                                            ))}
                                                            </div>
                                                            {(formik.touched[`gender-${index}`] || formik.submitCount > 0) && formik.errors[`gender-${index}`] && (
                                                                <div className="error">{formik.errors[`gender-${index}`]}</div>
                                                            )}
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="grid mb-0">
                                                    <div className="col-12">
                                                        <div className="flex flex-column form-input gap-2">
                                                            <label htmlFor={`turnover-${index}`}>How much did the suspicious turnover account for as a percentage of the event as a whole, if available?{isMember && <span className="important">*</span>}</label>
                                                            <PR.InputText
                                                                id={`turnover-${index}`}
                                                                name={`turnover-${index}`}
                                                                value={event.turnover || ''}
                                                                onChange={(e) =>  {handleNumericInput(e); handleEventDetailChange(index, e.target.value, 'turnover')}}
                                                                type="text"
                                                                aria-describedby="turnover"
                                                            />
                                                            {(formik.touched[`turnover-${index}`] || formik.submitCount > 0) && formik.errors[`turnover-${index}`] && (
                                                                            <div className="error-message">{formik.errors[`turnover-${index}`]}</div>
                                                            )}
                                                        </div>
                                                    </div>
                                                </div>
                                                <hr className="mt-4 mb-4" />
                                            </div>
                                        </div>
                                    ))}
                                </div>}
                            </div>
                            <div className="col-12 mb-3">
                                <div className="flex flex-column gap-2">
                                    <span className="label">Channel<span className="important">*</span></span>
                                    <div className="flex flex-wrap gap-3 mt-1">
                                    {channels.map((channel) => (
                                        <div className="flex align-items-center" key={channel.id}>
                                            <PR.Checkbox
                                                inputId={channel.id}
                                                value={channel.id}
                                                onChange={(e) => handleMultiSelect(e, 'channel')}
                                                checked={formik.values.channel?.includes(channel.id)}></PR.Checkbox>
                                            <label htmlFor={channel.id} className="ml-2 font-medium">{channel.name}</label>
                                        </div>
                                    ))}
                                    </div>
                                    {formik.errors.channel && (formik.touched.channel || formik.submitCount > 0) && (
                                        <div className="error">{formik.errors.channel}</div>
                                    )}
                                </div>
                            </div>
                            <div className="col-12 mb-3">
                                <div className="flex flex-column gap-2">
                                    <span className="label">Time of bet<span className="important">*</span></span>
                                    <div className="flex flex-wrap gap-3 mt-1">
                                    {betTimeOptions.map((option) => (
                                        <div className="flex align-items-center" key={option.id}>
                                            <PR.Checkbox
                                                inputId={option.id}
                                                value={option.id}
                                                onChange={(e) => handleMultiSelect(e, 'betTime')}
                                                checked={formik.values.betTime?.includes(option.id)}></PR.Checkbox>
                                            <label htmlFor={option.id} className="ml-2 font-medium">{option.name}</label>
                                        </div>
                                    ))}
                                    </div>
                                    {formik.errors.betTime && (formik.touched.betTime || formik.submitCount > 0) && (
                                        <div className="error">{formik.errors.betTime}</div>
                                    )}
                                </div>
                            </div>
                            <div className="col-12 mb-3">
                                <div className="flex flex-column gap-2">
                                    <span className="label">State of the bets<span className="important">*</span></span>
                                    <div className="flex flex-wrap gap-3 mt-1">
                                        {betsStateOptions.map((option) => (
                                            <div className="flex align-items-center" key={option.id}>
                                                <PR.Checkbox
                                                    inputId={option.id}
                                                    value={option.id}
                                                    onChange={(e) => handleMultiSelect(e, 'betsState')}
                                                    checked={formik.values.betsState?.includes(option.id)}></PR.Checkbox>
                                                <label htmlFor={option.id} className="ml-2 font-medium">{option.name}</label>
                                                </div>
                                        ))}
                                    </div>
                                    {formik.errors.betsState && (formik.touched.betsState || formik.submitCount > 0) && (
                                        <div className="error">{formik.errors.betsState}</div>
                                    )}
                                </div>
                            </div>
                            <div className="col-12">
                                <div className="flex flex-column gap-2">
                                    <span className="label">Max bets<span className="important">*</span></span>
                                    <div className="card flex mt-1 gap-3">
                                    {confirmOptions.map((option) => (    
                                        <div className="flex flex-wrap gap-2" key={option.id}>
                                            <div className="flex align-items-center">
                                                <PR.RadioButton
                                                    inputId={`bet-yes-${option.id}`}
                                                    name="bets"
                                                    value={option.id}
                                                    onChange={(e) => handleMaxBets(e.value)}
                                                    checked={maxBets === option.id}
                                                />
                                                <label htmlFor={`bet-yes-${option.id}`} className="ml-2 font-medium">{option.name}</label>
                                                </div>
                                            </div>
                                    ))}
                                    </div>
                                    {formik.errors.maxBets && (formik.touched.maxBets || formik.submitCount > 0) && (
                                        <div className="error">{formik.errors.maxBets}</div>
                                    )}
                                </div>
                            </div>
                            {editForm && (
                            <div className="col-12">
                                <div className="flex flex-column gap-2">
                                    <span className="label">When is the alert being raised?</span>
                                    <div className="card flex mt-1 gap-3">
                                    {alertsRaised.map((option) => (      
                                        <div className="flex flex-wrap gap-2" key={option.id}>
                                            <div className="flex align-items-center">
                                            <PR.RadioButton
                                                inputId={`pre-event-${option.id}`}
                                                name="raised"
                                                value={option.id}
                                                onChange={(e) => handleAlertTime(e.value)}
                                                checked={alertTime === option.id}
                                            />
                                            <label htmlFor={`pre-event-${option.id}`} className="ml-2 font-medium">{option.name}</label>
                                        </div>
                                    </div>
                                    ))}                                        
                                </div>
                            </div>
                            </div>
                            )}
                            <div className="col-12">
                                <p className="customer_info">CUSTOMER INFO</p>
                            </div>
                            <div className="col-12 mb-3">
                                <div className="flex flex-column gap-2">
                                    <span className="label">Type of customers<span className="important">*</span></span>
                                    <div className="flex flex-wrap gap-3 mt-1">
                                        {customerTypeOptions.map((option) => (
                                            <div className="flex align-items-center" key={option.id}>
                                                <PR.Checkbox
                                                    inputId={option.id}
                                                    value={option.id}
                                                    onChange={(e) => handleMultiSelect(e, 'customerType')}
                                                    checked={formik.values.customerType?.includes(option.id)}></PR.Checkbox>
                                                <label htmlFor={option.id} className="ml-2 font-medium">{option.name}</label>
                                            </div>
                                        ))}
                                    </div>
                                    {formik.errors.customerType && (formik.touched.customerType || formik.submitCount > 0) && (
                                        <div className="error">{formik.errors.customerType}</div>
                                    )}
                                </div>
                            </div>
                            <div className="col-12">
                                <div className="flex flex-column gap-2">
                                    <label htmlFor="newCustomer.count">Number of customers involved<span className="important">*</span></label>
                                    {/* Add More Section */}
                                    <div className="grid">
                                        <div className="col-4 form-input">
                                            <PR.InputText
                                                type="number"
                                                id="newCustomer.count"
                                                name="newCustomer.count"
                                                value={formik.values.newCustomer?.count || ''}
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                                placeholder="No. of customers"
                                                aria-describedby="events-help"
                                                min={0}
                                            />
                                            {formik.errors.newCustomer && (formik.touched.newCustomer || formik.submitCount > 0) && (
                                                <div className="error mt-1">{formik.errors.newCustomer.count}</div>
                                            )}
                                        </div>
                                        <div className="col-5">
                                            <PR.Dropdown
                                                inputId="newCustomer.country"
                                                name="newCustomer.country"
                                                value={formik.values.newCustomer?.country || ''}
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                                filter
                                                options={location} optionLabel="name"
                                                placeholder="Select country"
                                                className="w-full alert-drop-down"
                                            />
                                            {formik.errors.newCustomer && (formik.touched.newCustomer || formik.submitCount > 0) && (
                                                <div className="error mt-1">{formik.errors.newCustomer.country}</div>
                                            )}
                                            {displayStatesForSelectedCountries.includes(formik.values.newCustomer?.country?.name) && <PR.Dropdown
                                                inputId="newCustomer.state"
                                                name="newCustomer.state"
                                                filter
                                                resetFilterOnHide
                                                value={formik.values.newCustomer?.state || ''}
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                                options={formik.values.newCustomer?.country.states}
                                                placeholder="Select state"
                                                className="w-full alert-drop-down"
                                            />}
                                            {(formik.errors.newCustomer && (formik.touched.newCustomer || formik.submitCount > 0)) && (
                                                <div className="error-message mt-1">{formik.errors.newCustomer.state}</div>
                                            )}
                                        </div>
                                        <div className="col-3 form-input">
                                            <PR.Button label="Add More" type="button" icon="pi pi-plus" className="addmore-button" onClick={handleAdd} />
                                        </div>
                                    </div>
                                    {formik.values.customers?.map((customer, index) => {
                                        return (
                                            <div className="grid" key={index}>
                                                <div className="col-4 form-input">
                                                    <PR.InputText
                                                        id={`customers[${index}].count`}
                                                        name={`customers[${index}].count`}
                                                        type="number"
                                                        value={formik.values.customers[index].count}
                                                        onChange={formik.handleChange}
                                                        onBlur={formik.handleBlur}
                                                        placeholder="No. of customers"
                                                        aria-describedby="events-help"
                                                        min={0}
                                                    />
                                                    {formik.touched.customers && formik.touched.customers[index] && formik.errors.customers && formik.errors.customers[index] && formik.errors.customers[index].count && (
                                                        <div className="error mt-1">{formik.errors.customers[index].count}</div>
                                                    )}
                                                </div>
                                                <div className="col-5">
                                                    <PR.Dropdown
                                                        inputId={`customers[${index}].country`}
                                                        name={`customers[${index}].country`}
                                                        value={formik.values.customers[index].country}
                                                        onChange={formik.handleChange}
                                                        onBlur={formik.handleBlur}
                                                        filter
                                                        options={location} optionLabel="name"
                                                        placeholder="Select country"
                                                        className="w-full alert-drop-down"
                                                    />
                                                    {formik.touched.customers && formik.touched.customers[index] && formik.errors.customers && formik.errors.customers[index] && formik.errors.customers[index].country && (
                                                        <div className="error mt-1">{formik.errors.customers[index].country}</div>
                                                    )}

                                                    {displayStatesForSelectedCountries.includes(formik.values.customers[index]?.country.name) &&
                                                        <PR.Dropdown
                                                        inputId={`customers[${index}].state`}
                                                        name={`customers[${index}].state`}
                                                        value={formik.values.customers[index].state}
                                                        filter
                                                        resetFilterOnHide
                                                        onChange={formik.handleChange}
                                                        onBlur={formik.handleBlur}
                                                        options={formik.values.customers[index].country.states}
                                                        placeholder="Select state"
                                                        className="w-full alert-drop-down"
                                                    />
                                                    }
                                                    {formik.touched.customers && formik.touched.customers[index] && formik.errors.customers && formik.errors.customers[index] && formik.errors.customers[index].state && (
                                                        <div className="error-message mt-1">{formik.errors.customers[index].state}</div>
                                                    )}
                                                </div>
                                                <div className="col-3 form-input">
                                                    <PR.Button
                                                        label="Remove"
                                                        type="button"
                                                        icon="pi pi-minus"
                                                        className="addmore-button"
                                                        onClick={() => handleDelete(index)}
                                                    />
                                                </div>
                                            </div>
                                        )
                                    })}
                                    {/* Add More Section */}
                                </div>
                            </div>
                            <div className="col-12 mb-2">
                                <div className="flex flex-column gap-2">
                                    <label htmlFor="description">Description of suspicious event<span className="important">*</span></label>
                                    <div className="card flex justify-content-center">
                                        <PR.InputTextarea
                                            id="description"
                                            name="description"
                                            value={formik.values.description}
                                            onChange={(e) => {
                                                const newValue = e.target.value;
                                                formik.setFieldValue('description', newValue);
                                            }}
                                            onPaste={(e) => {
                                                e.preventDefault();
                                                const pastedData = e.clipboardData.getData('text');
                                                const input = e.target;
                                                const { selectionStart, selectionEnd, value } = input;
                                                const newDescription = value.slice(0, selectionStart) + pastedData + value.slice(selectionEnd);
                                                formik.setFieldValue('description', newDescription);
                                                setTimeout(() => {
                                                    input.setSelectionRange(selectionStart + pastedData?.length, selectionStart + pastedData?.length);
                                                }, 0);
                                            }}
                                            onBlur={formik.handleBlur}
                                            rows={8} cols={30}
                                            className="description-textarea"
                                        />
                                    </div>
                                   
                                    {formik.errors.description && (formik.touched.description || formik.submitCount > 0) && (
                                        <div className="error">{formik.errors.description}</div>
                                    )}
                                </div>
                            </div>
                            <div className="col-7">
                                <div className="flex flex-column gap-2">
                                    <span className="label">Attach File</span>
                                    <div className="card file-upload flex align-items-center">
                                        <PR.FileUpload
                                            auto
                                            mode="advanced"
                                            name="attachments"
                                            accept={aletFormForValidation?.toString()}
                                            chooseLabel="Choose File"
                                            ref={fileUploadRef}
                                            onSelect={handleFileSelect}
                                            onFocus={() => formik.setFieldTouched(`attachments`)}
                                            customUpload
                                        />
                                        <span className="ml-2"> <span className="block">(PDF, DOCX, XLSX)</span>{validationMessages.sizeExceed}</span>
                                    </div>

                                    {formik.values.attachments && (
                                        <div className="uploaded-file-container flex flex-column gap-2">
                                            {formik.values.attachments?.map((attachment, index) => (
                                                <div className="uploaded-files" key={index}>
                                                    {attachment.name}
                                                    <i className="pi pi-trash" onClick={() => handleDeleteFile(index)} />
                                                </div>
                                            ))}
                                        </div>
                                    )}
                                    {formik.errors.attachments && (formik.touched.attachments || formik.submitCount > 0) && (
                                        <div className="error-message" style={{ color: "red" }}>
                                            {Array.isArray(formik.errors.attachments) ? (
                                                Array.from(new Set(formik.errors?.attachments))?.map((error, index) => (
                                                    <div key={index}>{error}</div>
                                                ))
                                            ) : (
                                                <div>{formik.errors.attachments}</div>
                                            )}
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>
                        <div className="error-message">{showError}</div>
                         <ErrorMessage id={errorId} />
                        <div className="form-footer flex align-items-center justify-content-end gap-3 mt-2">
                            <PR.Button
                                label={backLabel}
                                type="button"
                                onClick={handleBack}
                                disabled={alertLoading}
                                className="cancel-button"
                            />
                            <PR.Button
                                label={nextLabel}
                                className="create-alert-button"
                                disabled={showError || alertLoading}
                                loading={alertLoading}
                                type="submit"
                            />
                        </div>
                    </div>
                </form>
            </div>
            {/* section ends here */}
        </>
    );
};

export default AlertForm;