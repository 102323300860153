import React, { useMemo, useEffect, useState, useRef } from "react";
import * as PR from "../../prime-react/index";
import "./Profile.scss";
import Layout from "../../layouts/Layout";
import ProfileMenuBar from "../ProfileMenuBar";
import { useDispatch, useSelector } from 'react-redux';
import * as Yup from "yup";
import { Form, Formik } from "formik";
import { getFileDetails, updateProfile } from "../../services/api";
import { authActions } from "../../store/auth";
import { fileUploadLimit, validationMessages } from "../../utils/reuse";
import {profileIcon} from "../../assets/images";
import { useToast } from "../../context/ToastContext";
import { filterOptionsActions } from "../../store/FiltersOptions";

const Profile = () => {
    const [showError, setshowError] = useState('');
    const { showToast } = useToast();
    const role = useSelector(state => state.auth.role);
    const sessionData = useSelector(state => state.auth.sessionData);
    const userImage = useSelector(state => state.auth.userImage);
    const headers = useMemo(() => ({ sessionid: sessionData.sessionId }), [sessionData.sessionId]);
    const dispatch = useDispatch();
    const [profilePic, setProfilePic] = useState(null);
    const [loading, setLoading] = useState(true);
    const [showPassword, setShowPassword] = useState(false);
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);
    const deleteImage = useRef(null); 
    const profileData = useSelector(state => state.auth.profileData);

    const blobKey = userImage;
 
    useEffect(() => {
        if(blobKey && blobKey !== null){
            setProfilePic(URL.createObjectURL(blobKey));
            setLoading(false);
        }else if (!blobKey && profileData?.profilePic) {
            if (profileData?.profilePic?.key && (profileData?.profilePic.type === "image/jpeg" || profileData?.profilePic.type === "image/jpg" || profileData?.profilePic.type === "image/png")) {
                getFileDetails(profileData?.profilePic.key, headers, dispatch, response => {
                    if (response.result === 'SUCCESS') {
                        setProfilePic(URL.createObjectURL(response?.data));
                    }
                });
            } 
            setLoading(false);
        }else{
            setLoading(false);
        }
    // eslint-disable-next-line
    },[])

    const validationSchema = Yup.object({
        name: Yup.string().required("User name is required").max(50, "User name must be 50 characters or less"),
        profilePhoto: Yup.mixed()
            .nullable()
            .test("fileSize", validationMessages.imageSizeExceed, (value) => {
                return !value || value.size <= fileUploadLimit.value;
            })
            .test('fileType', validationMessages.onlyImage, (value) => {
                if (!value) return true; // If no file is provided, return true
                const acceptedTypes = ['image/jpeg', 'image/png', 'image/gif'];
                return acceptedTypes.includes(value.type);
            }),
        password: Yup.string()
            .notRequired()
            .matches(
                /^(?=.*[A-Z])(?=.*[0-9])(?=.*[!@$%&*()-])[a-zA-Z0-9!@$%&*()-]{8,}$/,
                "Password must contain at least one uppercase letter, one number, one special character from !@$%&*-(), and be at least 8 characters long."
            ).max(20, "Password must not exceed 20 characters"),
        confirmPassword: Yup.string().notRequired()
            .oneOf([Yup.ref('password'), null], "Passwords must match")
    });

    const setHeaderPic = (parseImage) => {
        if (parseImage?.key && (parseImage.type === "image/jpeg" || parseImage.type === "image/jpg" || parseImage.type === "image/png")) {
            getFileDetails(parseImage.key, headers, dispatch, response => {
                if (response.result === 'SUCCESS') {
                    dispatch(authActions.storeUserImage(response?.data))
                }
            });
        }
    }
    const initialValues = {
        name: profileData?.name,
        email: profileData?.email,
        profilePhoto: profileData?.profilePic,
        password: '',
        confirmPassword: ''
    };
    let imageUploadRef = useRef(null);
    const handleSubmit = (formValues, { setSubmitting, setErrors }) => {
        const payload = {
            name: formValues.name,
            email: formValues.email,
            id: sessionData.userID
        };
        if (profilePic === null) {
            payload.deleteImage = true;
        }

        if ((formValues.password !== '') && (formValues.password === formValues.confirmPassword)) {
            payload.password = formValues.password;
        }

        if ((formValues.password !== '') && (formValues.password !== formValues.confirmPassword)) {
            setshowError("Confirm password didn't match")
            setTimeout(() => {
                setshowError('')
            },4000)
            return
        }

        const formData = new FormData();
        formData.append("data", JSON.stringify(payload));
        if (formValues.profilePhoto && formValues.profilePhoto?.key === undefined && profilePic !== null) {
            formData.append("attachments", formValues.profilePhoto);
        }
        updateProfile(formData, headers, dispatch, (response) => {
            if (response.result === "SUCCESS") {
                const profileInfo = {
                    ...profileData,
                    name: formValues.name,
                }
                if (response.data === 'ok' && payload.deleteImage) {
                    profileInfo.profilePic = null
                    dispatch(authActions.storeUserImage(null))
                } else if (response.data !== 'ok') {
                    profileInfo.profilePic = response.data
                    dispatch(authActions.storeUserImage(null))
                    setHeaderPic(response.data)
                }
                 dispatch(authActions.setProfileData(profileInfo));
                if (formValues.password) {
                    showToast("success", "Your password has been successfully updated. Please log in again.");
                    dispatch(authActions.onLogout());
                    dispatch(filterOptionsActions.clearFilterOptions());
                } else {
                    showToast("success", "Profile updated successfully");
                }

            } else {
                const error = response.error;
                setshowError(error?.errorMsg || error?.summary)
                setTimeout(() => {
                    setshowError('')
                },4000)
            }
            setSubmitting(false);
        });
    };
    const handleDeleteImage = () => {
        deleteImage.current = false;
        setProfilePic(null);
    };

    const handleFileChange = (event, setFieldValue, errors) => {
        const file = event.files[0];
        if (file) {
            if (file.size > fileUploadLimit.label) {
                setProfilePic(null); // Reset the image preview
                errors.profilePhoto = validationMessages.imageSizeExceed; // Set error message
                return;
            } else {
                setFieldValue("profilePhoto", event.files[0]);
            }
            // If no errors, set the image preview
            const objectURL = URL.createObjectURL(file);
            setProfilePic(objectURL);
        }
    };

    return (
        <>
            <Layout backBtn={'home'} backBtnLabel="Home">
                {
                    loading
                        ? (<div className="loading"> <i className="pi pi-spin pi-spinner"></i> </div>)
                        : (<section className="profile-page-section">
                            <div className="profile-wrapper">
                                <div className="profile-tabs-section">
                                    <div className="grid justify-content-center">
                                        <div className="col-9">
                                            <div >
                                                <h1>Profile</h1>
                                            </div>
                                            <Formik
                                                initialValues={initialValues}
                                                validationSchema={validationSchema}
                                                onSubmit={(values, { setSubmitting }) =>
                                                    handleSubmit(values, { setSubmitting })
                                                }
                                                enableReinitialize={true}
                                            >
                                                {({
                                                    isSubmitting,
                                                    errors,
                                                    touched,
                                                    values,
                                                    handleChange,
                                                    setFieldValue,
                                                    handleBlur,
                                                    dirty
                                                }) => {
                                                    return (
                                                        <Form id="profileForm">
                                                            <div className="form-section">
                                                                <div className="section">
                                                                    <div className="form-header">
                                                                        <p>Personal Details</p>
                                                                    </div>
                                                                    <div className="form-body">
                                                                        <div className="grid align-items-center mb-2">
                                                                            <div className="col-4">
                                                                                <div className="flex flex-column gap-2">
                                                                                    <span className="label">Profile pic</span>
                                                                                </div>
                                                                            </div>
                                                                            <div className="col-8">
                                                                                <div className="flex flex-column gap-2 relative">
                                                                                    <div className="profile-upload">
                                                                                        <PR.FileUpload
                                                                                            auto
                                                                                            mode="advanced"
                                                                                            name="profilePhoto"
                                                                                            accept="image/*"
                                                                                            ref={imageUploadRef}
                                                                                            label="false"
                                                                                            customUpload
                                                                                            onSelect={(event) => {
                                                                                                if (errors.profilePhoto) {
                                                                                                    errors.profilePhoto = null;
                                                                                                }
                                                                                            
                                                                                                handleFileChange(event, setFieldValue, errors);
                                                                                            }}

                                                                                        />
                                                                                        {profilePic ? <PR.Image
                                                                                            src={profilePic}
                                                                                            alt={'news?.title'}
                                                                                            className="profile-photo"
                                                                                        /> :
                                                                                        <PR.Image src={`${profileIcon}?v=${new Date().getTime()}`} alt="profileIcon" className="profile-photo"/>
                                                                                        }
                                                                                    </div>
                                                                                    {profilePic && (
                                                                                        <span className="delete-icon"><i className="pi pi-trash" onClick={handleDeleteImage}></i></span>
                                                                                    )}
                                                                                </div>
                                                                                {(errors.profilePhoto || touched.profilePhoto) && (
                                                                                    <div className="error-message">{errors.profilePhoto}</div>
                                                                                )}  
                                                                            </div>
                                                                        </div>
                                                                        <div className="grid align-items-center mb-2">
                                                                            <div className="col-4">
                                                                                <div className="flex flex-column gap-2">
                                                                                    <label htmlFor="name">Name</label>
                                                                                </div>
                                                                            </div>
                                                                            <div className="col-8">
                                                                                <div className="flex flex-column gap-2">
                                                                                    <PR.InputText
                                                                                        id="name"
                                                                                        name="name"
                                                                                        value={values.name}
                                                                                        aria-describedby="name"
                                                                                        onChange={handleChange}
                                                                                        onBlur={handleBlur}
                                                                                        autoComplete="name"
                                                                                        />
                                                                                </div>
                                                                                {errors.name && touched.name && (
                                                                                    <div className="error-message">{errors.name}</div>
                                                                                )}
                                                                            </div>
                                                                        </div>
                                                                        <div className="grid align-items-center mb-2">
                                                                            <div className="col-4">
                                                                                <div className="flex flex-column gap-2">
                                                                                    <label htmlFor="email">Email address</label>
                                                                                </div>
                                                                            </div>
                                                                            <div className="col-8">
                                                                                <div className="flex flex-column gap-2">
                                                                                    <PR.InputText
                                                                                        id="email"
                                                                                        name="email"
                                                                                        aria-describedby="email-help"
                                                                                        value={values.email}
                                                                                        disabled
                                                                                        autoComplete="username"
                                                                                    />
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div className="grid align-items-center">
                                                                            <div className="col-4">
                                                                                <div className="flex flex-column gap-2">
                                                                                    <span className="label">Company</span>

                                                                                </div>
                                                                            </div>
                                                                            <div className="col-8">
                                                                                <div className="flex flex-column gap-2">
                                                                                    <span className="company-name">{sessionData?.groupName}</span>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>

                                                                <div className="mt-4">
                                                                    <div className="section">
                                                                        <div className="form-header">
                                                                            <p>Change Password</p>
                                                                        </div>
                                                                        <div className="form-body">
                                                                            <div className="grid align-items-start mb-2">


                                                                            </div>
                                                                            <div className="grid align-items-start mb-2">
                                                                                <div className="col-4">
                                                                                    <div className="flex flex-column gap-2">
                                                                                        <label htmlFor="password">New password</label>
                                                                                    </div>
                                                                                </div>
                                                                                <div className="col-8">
                                                                                    <div className="password-wrapper">
                                                                                        <PR.InputText
                                                                                            id="password"
                                                                                            name="password"
                                                                                            type={showPassword ? "text" : "password"}
                                                                                            value={values.password}
                                                                                            onChange={handleChange}
                                                                                            onBlur={handleBlur}
                                                                                            aria-describedby="password"
                                                                                            className="p-inputtext password-input"
                                                                                            autoComplete="new-password"
                                                                                        />
                                                                                        <i
                                                                                            className={`pi ${showPassword ? "pi-eye-slash" : "pi-eye"} eye-icon`}
                                                                                            onClick={() => setShowPassword(prev => !prev)}
                                                                                        ></i>
                                                                                    </div>
                                                                                    {errors.password && touched.password && (
                                                                                        <div className="error-message">{errors.password}</div>
                                                                                    )}
                                                                                </div>
                                                                            </div>
                                                                            <div className="grid align-items-start mb-2">
                                                                                <div className="col-4">
                                                                                    <div className="flex flex-column gap-2">
                                                                                        <label htmlFor="confirmPassword">Confirm password</label>
                                                                                    </div>
                                                                                </div>
                                                                                <div className="col-8">
                                                                                    <div className="password-wrapper">
                                                                                        <PR.InputText
                                                                                            id="confirmPassword"
                                                                                            name="confirmPassword"
                                                                                            type={showConfirmPassword ? "text" : "password"}
                                                                                            value={values.confirmPassword}
                                                                                            onChange={handleChange}   
                                                                                            onBlur={handleBlur}                                                                                      aria-describedby="confirmPassword"
                                                                                            className="p-inputtext password-input"
                                                                                            autoComplete="new-password"

                                                                                        />
                                                                                        <i
                                                                                            className={`pi ${showConfirmPassword ? "pi-eye-slash" : "pi-eye"} eye-icon`}
                                                                                            onClick={() => setShowConfirmPassword(prev => !prev)}
                                                                                        ></i>
                                                                                    </div>
                                                                                    {errors.confirmPassword && touched.confirmPassword && (
                                                                                        <div className="error-message">{errors.confirmPassword}</div>
                                                                                    )}
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div className="error-message text-center">{showError}</div>
                                                                    </div>
                                                                </div>
                                                                <div className="form-footer">
                                                                    <PR.Button
                                                                        label="Update User"
                                                                        disabled={!(dirty||typeof deleteImage.current === 'boolean')}
                                                                        className={`${(role === 'regulator' || role === 'governingbody') && 'regulator'} save-button`}
                                                                        type="submit"
                                                                        form="profileForm"
                                                                    />
                                                                </div>
                                                            </div>
                                                        </Form>
                                                    )
                                                }}
                                            </Formik>

                                        </div>
                                        {(role !== 'analyst') && <div className="col-3 right-menu">
                                            <div className="vertical-menu">
                                                <ProfileMenuBar />
                                            </div>
                                        </div>
                                        }
                                    </div>
                                </div>
                            </div>
                        </section>)
                }
            </Layout>
        </>
    );
};

export default Profile;