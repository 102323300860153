import React, { useRef, useState } from "react";
import * as PR from "../prime-react/index";
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { logo, logoDark } from "../assets/images/index";
import { Link, useNavigate } from 'react-router-dom';
import { useDispatch,useSelector } from 'react-redux';
import { logout } from "../services/api";
import ProfileImageDisplay from "../shared/ProfileImageDisplay";
import { newsActions } from "../store/news";
import { resetFilter, resetReportFilter } from "../store/filter.slice";
import { ErrorMessage, useToast } from "../context/ToastContext";

const logoutApiError = 'logout_api_error'

const Header = () => {
  const { showToast, clearError } = useToast();
  const menu = useRef(null);
  const navigate = useNavigate();
  const userMenu = useRef(null);
  const [visible, setVisible] = useState(false);
  const dispatch = useDispatch()
  const sessionData = useSelector(state => state?.auth?.sessionData);
  const profileData = useSelector(state => state?.auth?.profileData);
  const menuLinking = useSelector(state => state?.headerMenu);
  const role = useSelector(state => state.auth.role);

  const handleLogout = () => {
    clearError(logoutApiError)
    const headers = { sessionid: sessionData.sessionId };
    logout(headers, dispatch, (response) => {
      if (response.result === 'SUCCESS') {
        navigate('/login');
        dispatch(resetFilter());
        dispatch(resetReportFilter());
      } else {
        const error = response.error;        
        showToast(error.severity, error?.errorMsg || error?.summary, logoutApiError);
      }
    });
  };
  const command = (url,{originalEvent}) =>{
  //  originalEvent.preventDefault();
    navigate(url);
  }
  const allMenuItems = [
    {
      label: "Profile Details",
      icon: "pi pi-user",
      command: command.bind(null,'/profile-details'),
      isVisible: true
    },
    {
      label: "Logout",
      icon: "pi pi-power-off",
      isVisible: true,
      command: () => {
        setVisible(true);
      }
    }
  ];

  const menuItems = allMenuItems.filter(item => item.isVisible);

  
  const userMenuItems = [
    {
      label: "Alerts",
      icon: "pi pi-list",
      command: command.bind(null,'/home')
    },
    {
      label: "Alert Tags",
      icon: "pi pi-th-large",
      command: command.bind(null,'/tags')
    },
    {
      label: "Case Updates",
      icon: "pi pi-share-alt",
      command: command.bind(null,'/case-updates')
    },
    {
      label: "User Management",
      icon: "pi pi-users",
      command: command.bind(null,'/user-management')
    },
    {
      label: "Member Analytics",
      icon: "pi pi-chart-bar",
      command: command.bind(null,'/my-analytics')
    },
    {
      label: "News & Updates",
      icon: "pi pi-book",
      command: ({originalEvent}) => {originalEvent.preventDefault(); dispatch(newsActions.updateTab({activeIndex:0})); navigate('/news')}
    },
    {
      label: "Reports",
      icon: "pi pi-file",
      command: command.bind(null,'/reports')
    }
  ];
  
  const footerContent = (
      <div>
          <ErrorMessage id={logoutApiError} />
          <PR.Button label="Cancel" onClick={() => {clearError(logoutApiError); setVisible(false);}} outlined className="logout-btns" />
          <PR.Button label="Logout" onClick={() => handleLogout()} outlined autoFocus className={` ${(role === 'regulator' || role === 'governingbody') && 'regulator'} logout-btns click-btn`} />
      </div>
  );

  const gotoCreateAlert=()=>{
    navigate("/create-alert");
}

  const start = <Link to="/home"><PR.Image src={role === 'analyst' ? logoDark : logo} alt="IBIA Logo" className="logo-img" /></Link>;
  const startBack = <Link to={`/${menuLinking.backBtn}`} className="flex align-items-center back-btn"> <i className="pi pi-arrow-left" />Back to {menuLinking.backBtnLabel || menuLinking.backBtn}</Link>

  const end = <div className="flex align-items-center gap-4">
                <Link to="/help" className="right-icons"><i className="pi pi-question-circle"></i> {(role !== 'regulator' && role !== 'governingbody') && 'Help guide'}</Link>
                {  role === 'analyst' && (
                  <>
                  <PR.Button className="create-button" severity="primary" label="Create Alert" onClick={() => gotoCreateAlert()}/>
                  <PR.Button
                      icon="pi pi-bars"
                      className="menu-bars-button"
                      onClick={(e) => userMenu.current.toggle(e)}
                  />
                </>
              )}
                <PR.Menu model={userMenuItems} popup popupAlignment="right" ref={userMenu} />
                <span className="line"></span>
                <span className="flex align-items-center" onClick={(e) => menu.current.toggle(e)}>
                  <PR.Button icon={<ProfileImageDisplay profileImage={profileData?.profilePic} />} className="user-button" rounded outlined severity="secondary" aria-label="User" />
                  <PR.Button
                      icon="pi pi-chevron-down"
                      iconPos="right"
                      className="drop-down-button"
                  />
                 </span>
                <PR.Menu model={menuItems} popup ref={menu} />
              </div>;

  return (
    <>
      <HelmetProvider>
        <Helmet>
          <title>IBIA | Home</title>
        </Helmet>
      </HelmetProvider>
      <section className="header-section sticky-header">
        <div className="w-12">
          <header className="grid grid-nogutter">
              <div className="w-12 flex align-items-center">
                <PR.Menubar start={menuLinking.backBtn ? startBack : start} end={end} className="w-12 top-header" />
              </div>
          </header>
        </div>
      </section>
      <PR.Dialog visible={visible} footer={footerContent} className="logout-dialog">
          <div className="logout-section">
              <h2>Logout</h2>
              <p>Are you sure you want to logout?</p>
          </div>
      </PR.Dialog>
    </>
  )
}

export default Header